body{
    font-family: Arial, Helvetica, sans-serif
}
.outerWrapper{
    font-size:16pt;
    padding:1em;
    clear:both;
    text-align:center;
    margin:auto;
}

.headerWrapper{
    text-align:center;
}

.bodyWrapper{
    display:inline-flex;
    flex-wrap: row wrap;
    flex-direction: column;
    justify-content: space-between;
}

@media all and (max-width: 800px) {
    .bodyWrapper {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }
  }


.documentListWrapper,.formWrapper,.headerWrapper{
    margin:0.1em;
    padding:0.1em;
}

.documentListWrapper,.formWrapper,.headerWrapper h4{
  text-align:center;
}

table{
  width:100%;
  margin:1em 0;
  border:1px solid #ccc;
  padding: 1px;
}
.paginator{
  text-align:right;
}
.paginator a{
  margin: 0 0.5em;
}
.paginator .paginationText {
 margin:0 2em;

}
@media all and (max-width: 500px) {
    .bodyWrapper {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }
  }
