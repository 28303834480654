form.translateMeSubmitDocumentForm{
    border:1px solid #ddd;
}
form.translateMeSubmitDocumentForm h4{
    text-align:center;
}
form.translateMeSubmitDocumentForm ul{
    list-style: none;
    padding:0;
    margin:0;
}

form.translateMeSubmitDocumentForm ul li{
    margin: 1em;
}

form.translateMeSubmitDocumentForm ul li label,
form.translateMeSubmitDocumentForm ul li input,
form.translateMeSubmitDocumentForm ul li select
{
   display:block;
}

form.translateMeSubmitDocumentForm ul li input,
form.translateMeSubmitDocumentForm ul li select{

    border:1px solid black;
    padding:5px;
    width : 100%;
}

form.translateMeSubmitDocumentForm ul li label{
    margin: 20px 0 5px 0;
}