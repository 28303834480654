.header{
    display:inline-flex;
    flex-direction: column;
    flex-wrap: row wrap;
    justify-content:space-evenly;
}
.header h3{  text-align:center; }

.header button{
    background-color: #f0f1de;
    padding: 0.5em;
    margin:1em;
}