
/*
.document a.downloadLink{
    display:block;
    text-align:center;
    padding:0.25em;
    margin:10px 0 0 0;
    background-color : olivedrab;
    color:white;
    text-decoration: none;

}
*/

.document td{ 
    padding: 1em;
    margin: 3px 1px;
}

.document.active td{
    background-color: rgb(219, 248, 219);
}
.document.processing td{
    background-color:palegoldenrod;
}
.document.failed td{
    background-color: red
}