.loginBox{
    margin:auto;
    border:1px solid black;
    text-align: center;
    width:350px;
    margin-top:5em;
}
.loginBox input{
    display:block;
    padding:1em;
    margin:2em;
    width:250px;
    border:1px solid black;
}

.loginBox p
{
    text-align: center;
}

.loginBox p.error{
    color:red;
}
.loginBox p.hidden{
    display:none;
}

.loginBox input[type="submit"]{
    background-color: #f0f1de;
    width:279px;
}